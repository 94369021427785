import { Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { tagStyles } from './Tag@styles'
import classnames from 'classnames'

const useStyles = makeStyles(tagStyles)

interface ITagProps {
  identifier?: string
  label: string
  isActive?: boolean
  tooltip?: string
  onClick?: (id: string) => void
}

const Tag = (props: ITagProps) => {
  // props
  const { identifier, label, tooltip, onClick, isActive = false } = props

  // hooks
  const classes = useStyles()

  // reaction
  const behavior = isActive
    ? classnames([classes.root, classes.active])
    : classes.root

  // action
  const handleAction = (): void => {
    if (onClick && identifier) {
      return onClick(identifier)
    } else {
      return console.log(`Not set yet - ${identifier}`)
    }
  }

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <button className={behavior} onClick={handleAction}>
            <Typography variant="caption" display="block">
              {label}
            </Typography>
          </button>
        </Tooltip>
      ) : (
        <button className={behavior} onClick={handleAction}>
          <Typography variant="caption" display="block">
            {label}
          </Typography>
        </button>
      )}
    </>
  )
}

export default Tag
