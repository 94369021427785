export enum Collections {
  WebManagement = 'web-management',
  Management = 'managemennt',
  Daily_Menu = 'daily-menu',
  Opening_Hours = 'opening-hours',
  Food_Menu = 'food-menu'
}

export enum Documents {
  Orders = 'orders',
  Tasks = 'tasks',
  Domains = 'domains',
  Contacts = 'contacts',
  Questions = 'questions',
  // Daily menu
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  // Opening hours
  Days = 'days',
  // Enums
  Categories = 'categories',
  Files = 'files'
}

export enum SubCollections {
  Data = 'data',

  // DAILY MENU
  Menus = 'menus',
  Soups = 'soups',
}
