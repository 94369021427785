import {
  Avatar as MuiAvatar, Card,
  Grid, List, Typography
} from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import ContactsIcon from '@material-ui/icons/Contacts'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import { makeStyles } from '@material-ui/styles'
// icons
import InfoIcon from '@material-ui/icons/Info'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'

import { useTranslation } from 'react-i18next'
import * as config from '../../configs'
import { RouteName } from '../../enums/RouteName'
import useRoute from '../../hooks/useRoute'
import { IconDomains } from '../Icons'
import { LayoutStyles } from './Layout@styles'
import { DrawerContentPropsTypes } from './Layout@types'


const useStyles = makeStyles(LayoutStyles)

export const MainListItems = () => {
  const { t } = useTranslation()
  const route = useRoute()

  return (
    <>
      {config.app.canUseDashboard && (
        <ListItem button onClick={() => route.push(RouteName.Dashboard)}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Dashboard`} />
        </ListItem>
      )}
      {config.app.canUseHelpCenter && (
        <ListItem button onClick={() => route.push(RouteName.HelpCenter)}>
          <ListItemIcon>
            <LiveHelpIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Help_Center`} />
        </ListItem>
      )}
    </>
  )
}

export const ManagementMenuItems = () => {
  const { t } = useTranslation()
  const route = useRoute()

  return (
    <>
      <ListSubheader inset>{t`Menu.Management`}</ListSubheader>
      {config.app.canUseContacts && (
        <ListItem button onClick={() => route.push(RouteName.Contacts)}>
          <ListItemIcon>
            <ContactsIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Contacts`} />
        </ListItem>
      )}
      {config.app.canUseTasks && (
        <ListItem button onClick={() => route.push(RouteName.Tasks)}>
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Tasks`} />
        </ListItem>
      )}
      {config.app.canUseUsers && (
        <ListItem button onClick={() => route.push(RouteName.Users)}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Users`} />
        </ListItem>
      )}
    </>
  )
}

export const WebsitesMenuItems = () => {
  const { t } = useTranslation()
  const route = useRoute()

  return (
    <>
      <ListSubheader inset>{t`Menu.Web_Management`}</ListSubheader>
      {config.app.canUseOrders && (
        <ListItem button onClick={() => route.push(RouteName.Orders)}>
          <ListItemIcon>
            <CardTravelIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Orders`} />
        </ListItem>
      )}
      {config.app.canUseDomains && (
        <ListItem button onClick={() => route.push(RouteName.Domains)}>
          <ListItemIcon>
            <IconDomains />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Domains`} />
        </ListItem>
      )}
      {config.app.canUseQuestions && (
        <ListItem button onClick={() => route.push(RouteName.Question)}>
          <ListItemIcon>
            <QuestionAnswerIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Question`} />
        </ListItem>
      )}
    </>
  )
}

export const GastronomyMenuItems = () => {
  const { t } = useTranslation()
  const route = useRoute()

  return (
    <>
      <ListSubheader inset>{t`Menu.Gastronomy`}</ListSubheader>
      {config.app.canUseFoodmenu && (
        <ListItem button onClick={() => route.push(RouteName.FoodMenu)}>
          <ListItemIcon>
            <RestaurantMenuIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Food_Menu`} />
        </ListItem>
      )}
      {config.app.canUseDailyMenu && (
        <ListItem button onClick={() => route.push(RouteName.DailyMenu)}>
          <ListItemIcon>
            <FastfoodIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Daily_Menu`} />
        </ListItem>
      )}
      {config.app.canUseOpeningHours && (
        <ListItem button onClick={() => route.push(RouteName.OpeningHours)}>
          <ListItemIcon>
            <QueryBuilderIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.Opening_Hours`} />
        </ListItem>
      )}
    </>
  )
}

export const AppMenuItems = () => {
  const { t } = useTranslation()
  const route = useRoute()

  return (
    <>
      <ListSubheader inset>{t`Menu.Application`}</ListSubheader>
      {config.app.canUseAboutApp && (
        <ListItem button onClick={() => route.push(RouteName.About)}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={t`Menu.About_App`} />
        </ListItem>
      )}
    </>
  )
}

export const DrawerContent = (props: DrawerContentPropsTypes) => {
  // props
  const {
    getSiderNavWidth,
    logoUrl,
    getFirstLetterOfUsername,
    userData,
  } = props
  // hooks
  const { t } = useTranslation()
  const classes = useStyles({ getSiderNavWidth })

  return (
    <>
      <div className={classes.logo}>
        <img src={`/images/admin-logo-${logoUrl}.png`} alt="logo" />
      </div>
      <div className={classes.profile}>
        <Card className={classes.profileCard} variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.profileCardAvatar}>
              <MuiAvatar>{getFirstLetterOfUsername}</MuiAvatar>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" display="block">
                {userData?.displayName || t`Global.Not_Filled`}
              </Typography>
              <Typography variant="caption" display="block">
                Grand Admin
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
      <List>
        <MainListItems />
      </List>
      <List>
        {config.app.canUseManagement && <ManagementMenuItems />}
      </List>
      <List>
        {config.app.canUseWebManagement && <WebsitesMenuItems />}
      </List>
      <List>
        {config.app.canUseGastronomy && <GastronomyMenuItems />}
      </List>
      <List>
        {config.app.canuseApplicationMenu && <AppMenuItems />}
      </List>
    </>
  )
}
