import {
  Button, FormControlLabel, Grid, IconButton, InputAdornment, Menu,
  MenuItem, Paper, Switch, TextField, Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { ChangeEvent, MouseEvent, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from '../../components/Tag';
import allergens from '../../lib/local-storage/static-data/allergens';
import { MenusContext } from '../../providers/menus.provier';
import { IDailyMenus } from '../../types/daily-menu.types';
import { partsStyles } from './DailyMenu@styles';
import { MenuSettingsPropsTypes } from './DailyMenu@types';

const useStyles = makeStyles(partsStyles);

const MenuSettings = (props: MenuSettingsPropsTypes) => {
  // props
  const { data, index, documents } = props;

  // state
  const [unsave, setUnsave] = useState<boolean>(false);
  const [submenu, setSubmenu] = useState<null | HTMLElement>(null);
  const [state, setState] = useState<IDailyMenus>({
    menuName: data.menuName,
    menuPrice: data.menuPrice,
    menuAmount: data.menuAmount,
    menuAllergens: data.menuAllergens,
    isBusiness: data.isBusiness,
    isMinute: data.isMinute,
    isMenuActive: data.isMenuActive
  });

  // hooks
  const { t } = useTranslation();
  const classes = useStyles();
  const { removeMenu, updateMenu } = useContext(MenusContext);
  const menuId = data?.id || '';
  const activatedClasses = state.isMenuActive
    ? classnames([classes.paper, classes.activated])
    : classes.paper;


  /**
   * If is allergen in state, system detect active allergen
   * @param identifier
   * @returns
   */
  const checkActiveAllergen = (identifier: string): boolean => {
    const arr: string[] = [...state.menuAllergens];
    return arr.includes(identifier) || false;
  };

  /**
   * If is active allergen and user click on allergen again,
   * system will remove that allergen from state so result is
   * current active allergens. Also if user click on unactive allergen
   * system will add that allergen to state and results is active allergens
   * @param identifier
   * @returns
   */
  const handleAddAllergen = (identifier: string): void => {
    setUnsave(true);
    if (checkActiveAllergen(identifier)) {
      return setState((prevState) => ({
        ...prevState,
        menuAllergens: state.menuAllergens.filter((item) => {
          return item !== identifier;
        }),
      }));
    } else {
      return setState((prevState) => ({
        ...prevState,
        menuAllergens: [...state.menuAllergens, identifier],
      }));
    }
  };

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUnsave(true);
    return setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSwitch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUnsave(true);
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  }, [state]);

  const handleUpdateMenu = useCallback(() => {
    setUnsave(false);
    updateMenu(menuId, documents, state);
  }, [updateMenu, menuId, documents, state]);

  const handleRemoveMenu = useCallback(() => {
    removeMenu(menuId, documents);
  }, [removeMenu, menuId, documents]);

  const handleActiveMenu = useCallback(() => {
    const data = {
      isMenuActive: !state.isMenuActive
    };
    setState((prevState) => ({
      ...prevState,
      isMenuActive: !state.isMenuActive
    }));

    return updateMenu(menuId, documents, data);
  }, [documents, menuId, state.isMenuActive, updateMenu]);

  const handleOpenSubmenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setSubmenu(event.currentTarget);
  }, []);

  const handleCloseSubmenu = useCallback(() => {
    return setSubmenu(null);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={activatedClasses}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="overline" gutterBottom>
                {`${t`Daily_Menu.Menu`} ${index + 1}`}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.right}>
              <Switch
                color="primary"
                name="isMenuActive"
                checked={state.isMenuActive}
                onChange={handleActiveMenu}
              />
              <IconButton size="small" onClick={handleOpenSubmenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-menu"
                anchorEl={submenu}
                keepMounted
                open={Boolean(submenu)}
                onClose={handleCloseSubmenu}
              >
                <MenuItem onClick={handleRemoveMenu}>
                  <Typography variant="inherit" color='secondary'>{t('Global.Remove')}</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <div className={classes.spacing}>
            <Grid container spacing={1}>
              <Grid item md={2} xs={12}>
                <TextField
                  id={`menu-amount${index}`}
                  name="menuAmount"
                  label={t`Daily_Menu.Amount`}
                  variant="outlined"
                  value={state.menuAmount}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.lowercase}
                      >
                        {t('Global.Grams_Short')}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  id={`menu-name${index}`}
                  name="menuName"
                  label={t`Daily_Menu.Menu_Name`}
                  variant="outlined"
                  value={state.menuName}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  id={`menu-price${index}`}
                  name="menuPrice"
                  label={t`Daily_Menu.Price`}
                  variant="outlined"
                  value={state.menuPrice}
                  onChange={handleChange}
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InputAdornment position="end">
                          {t('Common.Currency.CZK')}
                        </InputAdornment>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={8} xs={12}>
                <div className={classes.left}>
                  <Typography variant="subtitle1">
                    {t('Daily_Menu.Allergens') + ':'}
                  </Typography>
                  {allergens.map((a) => (
                    <Tag
                      key={a.number}
                      identifier={a.identifier}
                      label={String(a.number)}
                      tooltip={t(`Common.Allergens.${a.loc}`)}
                      onClick={handleAddAllergen}
                      isActive={checkActiveAllergen(a.identifier)}
                    />
                  ))}
                </div>
              </Grid>
              <Grid item md={2} xs={12} className={classes.center}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.isMinute}
                      color="primary"
                      onChange={handleSwitch}
                      name="isMinute"
                    />
                  }
                  label={t(`Daily_Menu.Minute`)}
                />
              </Grid>
              <Grid item md={2} xs={12} className={classes.center}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.isBusiness}
                      color="primary"
                      onChange={handleSwitch}
                      name="isBusiness"
                    />}
                  label={t(`Daily_Menu.Business`)}
                />
              </Grid>

              <Grid item md={10} />
              <Grid item md={2} xs={12} className={classes.center}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!unsave}
                  onClick={handleUpdateMenu}
                >
                  {t`Global.Save`}
                </Button>
              </Grid>

            </Grid>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MenuSettings;
