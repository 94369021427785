import { Theme, createStyles } from '@material-ui/core'

export const dashboardStyles = (theme: Theme) =>
  createStyles({
    root: {},
    card: {
      padding: theme.spacing(4),
    },
    yearSelect: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })

export const dashboardCardStyles = (theme: Theme) =>
  createStyles({
    root: {},
    card: {
      padding: theme.spacing(4),
    },
    icon: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    title: {
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    number: {
      marginTop: theme.spacing(2),
    },
    right: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
