export const ordersChartData = [
  { month: 'Leden', ordersCount: 5 },
  { month: 'Únor', ordersCount: 7 },
  { month: 'Březen', ordersCount: 11 },
  { month: 'Duben', ordersCount: 13 },
  { month: 'Květen', ordersCount: 4 },
  { month: 'Červen', ordersCount: 8 },
  { month: 'Červenec', ordersCount: 10 },
  { month: 'Srpen', ordersCount: 14 },
  { month: 'Září', ordersCount: 21 },
  { month: 'Říjen', ordersCount: 17 },
  { month: 'Listopad', ordersCount: 6 },
  { month: 'Prosinec', ordersCount: 19 },
]

export const ordersTypeChartData = [
  {
    type: 'Basic',
    value: 36,
  },
  {
    type: 'Standard',
    value: 55,
  },
  {
    type: 'Enterprise',
    value: 12,
  }
]