import IAllergens from '../../../types/allergens.types'

const allergens: IAllergens[] = [
  {
    number: 1,
    identifier: 'cereals',
    loc: 'Cereals',
  },
  {
    number: 2,
    identifier: 'crustacean',
    loc: 'Crustacean',
  },
  {
    number: 3,
    identifier: 'egg',
    loc: 'Egg',
  },
  {
    number: 4,
    identifier: 'fish',
    loc: 'Fish',
  },

  {
    number: 5,
    identifier: 'peanut',
    loc: 'Peanut',
  },
  {
    number: 6,
    identifier: 'soya',
    loc: 'Soya',
  },
  {
    number: 7,
    identifier: 'milk',
    loc: 'Milk',
  },
  {
    number: 8,
    identifier: 'nuds',
    loc: 'Nuds',
  },
  {
    number: 9,
    identifier: 'celery',
    loc: 'Celery',
  },
  {
    number: 10,
    identifier: 'mustard',
    loc: 'Mustard',
  },
  {
    number: 11,
    identifier: 'sesame',
    loc: 'Sesame',
  },
  {
    number: 12,
    identifier: 'oxide-sulfr',
    loc: 'Oxide_Sulfr',
  },
  {
    number: 13,
    identifier: 'lupine',
    loc: 'Lupine',
  },
  {
    number: 14,
    identifier: 'molluscs',
    loc: 'Molluscs',
  },
]

export default allergens
