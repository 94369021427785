const app = {
  /********* MENU SETTINGS *********/
  canUseDashboard: true,
  canUseHelpCenter: false,
  // Management
  canUseManagement: false,
  canUseContacts: false,
  canUseTasks: false,
  canUseUsers: false,
  // Web management
  canUseWebManagement: false,
  canUseOrders: false,
  canUseDomains: false,
  canUseQuestions: false,
  // Gastronomy
  canUseGastronomy: true,
  canUseDailyMenu: false,
  canUseFoodmenu: true,
  canUseOpeningHours: false,
  canUseGastroEnum: true,
  // About app
  canuseApplicationMenu: false,
  canUseAboutApp: false,
  /********* APP SETTINGS *********/
  canUseGeneral: true,
  canUseProfile: true,
  canUseNotifications: false,
  canUseChangePassword: true,
  canUseSocialLinks: false,
}

export default app
