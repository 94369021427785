import { Documents } from '../../remote/Collections'
import {
  IDailyMenuInfoNew,
  IDailyMenus,
  IDailyMenuSoups,
} from '../../types/daily-menu.types'

export const tabsValues = {
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturtday: 'startday',
  sunday: 'sunday',
}

export const dailyMenuFieldNames = {
  date: 'date',
  description: 'description',
  isCanceled: 'isCanceled',
  reasonForCancel: 'reasonForCancel',
  name: 'name',
  amount: 'amount',
  price: 'price',
  allergens: 'allergens',
  isBusiness: 'isBusiness',
  number: 'number',
}

// Documents
export const documentsTuesday = Documents.Tuesday
export const documentsWednesday = Documents.Wednesday
export const documentsMonday = Documents.Monday
export const documentsThursday = Documents.Thursday
export const documentsFriday = Documents.Friday
export const documentsSaturday = Documents.Saturday
export const documentsSunday = Documents.Sunday

export const soupDummyData: IDailyMenuSoups = {
  isSoupActive: false,
  soupName: '',
  soupAmount: 0,
  soupPrice: '',
  soupAllergens: [],
}

export const menuDummyData: IDailyMenus = {
  menuName: '',
  menuPrice: '',
  menuAmount: 0,
  menuAllergens: [],
  isMenuActive: false,
  isMinute: false,
  isBusiness: false,
}

export const infoDummyData: IDailyMenuInfoNew = {
  menuDate: 0,
  lastUpdate: 0,
  reasonForCancel: '',
  extraText: '',
  isCanceled: false,
}
