import { Theme, createStyles } from '@material-ui/core'

export const tagStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minWidth: 40,
      margin: 2,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: 'rgb(0, 0, 0, 0)',
      border: '1px solid rgb(191, 199, 207)',
      color: theme.palette.text.secondary,
      borderRadius: 16,
      height: 24,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0, 0.04)',
      },
    },

    text: {
      color: theme.palette.text.primary
    },

    activateText: {
      color: 'rgb(0. 0. 0)',
    },

    active: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: 'rgb(255, 255, 255)',
    },
  })
