import { Grid } from '@material-ui/core'
import { useContext, useEffect } from 'react'
import { DataContext } from '../../providers/data.provider'
import { Collections, Documents, SubCollections } from '../../remote/Collections'
import { DummySkeleton, OpeningDay } from './OpeningHours@parts'

const OpeningHours = () => {
  const { get, data, isLoading } = useContext(DataContext)

  // remote settings
  const collection = Collections.Opening_Hours
  const documents = Documents.Days
  const subCollection = SubCollections.Data
  const orderBy = 'dayNumber'

  useEffect(() => {
    get({ collection, documents, subCollection, orderBy })
    // eslint-disable-next-line
  }, [collection, documents, subCollection, orderBy])

  const Skeleton = () => {
    return (
      <>
        <Grid item xs={12} md={4}>
          <DummySkeleton />
        </Grid>
        <Grid item xs={12} md={4}>
          <DummySkeleton />
        </Grid>
        <Grid item xs={12} md={4}>
          <DummySkeleton />
        </Grid>
      </>
    )
  }

  return (
    <Grid container spacing={2}>
      {
        isLoading
          ? <Skeleton />
          : data.map((item: any, index: number) => (
            <Grid key={index} item xs={12} md={4}>
              <OpeningDay
                collection={collection}
                documents={documents}
                subCollection={subCollection}
                {...item}
              />
            </Grid>
          ))}
    </Grid>
  )
}

export default OpeningHours
