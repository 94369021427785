import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@mui/material'
import TextField from '@mui/material/TextField'
import { ChangeEvent, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import { DataContext } from '../../providers/data.provider'
import { openingDayStyles } from './OpeningHours@styles'
import { IOpeningDayProps, IOpeningDayState } from './OpeningHours@types'

export const OpeningDay = (props: IOpeningDayProps) => {
  const { collection, documents, subCollection, id } = props
  const [isUnsaved, setIsUnsaved] = useState<boolean>(false)
  const [state, setState] = useState<IOpeningDayState>({
    day: props.day,
    dayNumber: props.dayNumber,
    title: props.title,
    dailyMenuOpeningFrom: props.dailyMenuOpeningFrom,
    dailyMenuOpeningTo: props.dailyMenuOpeningTo,
    menuOpeningFrom: props.menuOpeningFrom,
    menuOpeningTo: props.menuOpeningTo,
  })

  const useStyles = makeStyles(openingDayStyles)

  const { update } = useContext(DataContext)
  const classes = useStyles()
  const { t } = useTranslation();

  const handleChange = useCallback((
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsUnsaved(true)
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))
  }, [])

  const handleSave = () => {
    if (id) {
      update({ collection, documents, subCollection, id, data: state })
      setIsUnsaved(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom component="div">
                {t(state.title)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                align='center'
                variant="subtitle1"
                gutterBottom
                component="div"
              >
                {t`Opening_Hours.Daily_Menu`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="dailyMenuOpeningFrom"
                name="dailyMenuOpeningFrom"
                label={t`Opening_Hours.From`}
                type="time"
                defaultValue={state.dailyMenuOpeningFrom}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="dailyMenuOpeningTo"
                name="dailyMenuOpeningTo"
                label={t`Opening_Hours.To`}
                type="time"
                defaultValue={state.dailyMenuOpeningTo}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                align='center'
                variant="subtitle1"
                gutterBottom
                component="div"
              >
                {t`Opening_Hours.Menu`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="menuOpeningFrom"
                name="menuOpeningFrom"
                label={t`Opening_Hours.From`}
                type="time"
                defaultValue={state.menuOpeningFrom}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="menuOpeningTo"
                name="menuOpeningTo"
                label={t`Opening_Hours.To`}
                type="time"
                defaultValue={state.menuOpeningTo}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={!isUnsaved}
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {t`Global.Save`}
              </Button>
            </Grid>

          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export const DummySkeleton = () => {
  const useStyles = makeStyles(openingDayStyles)

  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton width="fullwidth">
                <Typography variant="h5" gutterBottom component="div">
                  Sample
                </Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="fullwidth">
                <Typography
                  align='center'
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                >
                  Sample
                </Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="fullwidth">
                <TextField
                  id="dailyMenuOpeningFrom"
                  name="dailyMenuOpeningFrom"
                  fullWidth
                />
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="fullwidth">
                <Typography
                  align='center'
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                >
                  Sample
                </Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="fullwidth">
                <TextField
                  id="dailyMenuOpeningFrom"
                  name="dailyMenuOpeningFrom"
                  fullWidth
                />
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="fullwidth">
                <Button
                  fullWidth
                >
                  Sample
                </Button>
              </Skeleton>
            </Grid>

          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}