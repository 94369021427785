import { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Layout } from '../components/Layout'
import * as config from '../configs'
import { RouteName } from '../enums/RouteName'
import { AuthContext } from '../providers/auth.provider'

// ROUTES
/* ROOT */
import { Dashboard } from './dashboard'
import { HelpCenter } from './help-center'
/* MANAGEMENT */
import { Contacts } from './contacts'
import { Tasks } from './tasks'
import { Users } from './users'
/* WEB MANAGEMENT */
import { Domains } from './domains'
import { Orders } from './orders'
import { Questions } from './questions'
/* GASTRONOMY */
import { DailyMenu } from './daily-menu'
import { Category, EditCategory, EditFood, FoodMenu, InsertCategory, InsertFood } from './food-menu'
import { OpeningHours } from './opening-hours'
/* APP */
import { About } from './about'
import { Login } from './login'
import { NotFound } from './not-found'
import { Settings } from './settings'
import { UserProfile } from './user-profile'

const Routing = () => {
  const { userId } = useContext(AuthContext)

  return (
    <Switch>
      {!userId ? (
        <Login />
      ) : (
        <Layout>
          <Switch>
            {/* ROOT */}
            {config.app.canUseDashboard && <Route path={RouteName.Dashboard} exact component={Dashboard} />}
            {config.app.canUseHelpCenter && <Route path={RouteName.HelpCenter} exact component={HelpCenter} />}

            {/* MANAGEMENT */}
            {config.app.canUseContacts && <Route path={RouteName.Contacts} exact component={Contacts} />}
            {config.app.canUseTasks && <Route path={RouteName.Tasks} exact component={Tasks} />}
            {config.app.canUseUsers && <Route path={RouteName.Users} exact component={Users} />}

            {/* WEB MANAGEMENT */}
            {config.app.canUseOrders && <Route path={RouteName.Orders} exact component={Orders} />}
            {config.app.canUseDomains && <Route path={RouteName.Domains} exact component={Domains} />}
            {config.app.canUseQuestions && <Route path={RouteName.Question} exact component={Questions} />}

            {/* GASTRONOMY */}
            {config.app.canUseDailyMenu && <Route path={RouteName.DailyMenu} exact component={DailyMenu} />}
            {config.app.canUseOpeningHours && (
              <Route
                path={RouteName.OpeningHours}
                exact
                component={OpeningHours}
              />
            )}
            {config.app.canUseFoodmenu && <Route path={RouteName.FoodMenu_Category_Id} component={Category} />}
            {config.app.canUseFoodmenu && <Route path={RouteName.FoodMenu} exact component={FoodMenu} />}
            {config.app.canUseFoodmenu && <Route path={RouteName.FoodMenu_InsertFood_Id} exact component={InsertFood} />}
            {config.app.canUseFoodmenu && <Route path={RouteName.FoodMenu_EditFood_Id} exact component={EditFood} />}
            {config.app.canUseFoodmenu && <Route path={RouteName.FoodMenu_InsertCategory} exact component={InsertCategory} />}
            {config.app.canUseFoodmenu && <Route path={RouteName.FoodMenu_EditCategory_Id} exact component={EditCategory} />}

            {/* APPS */}
            {config.app.canUseAboutApp && <Route path={RouteName.About} exact component={About} />}

            {/* USER */}
            <Route path={RouteName.UserProfile} exact component={UserProfile} />
            <Route path={RouteName.Settings} exact component={Settings} />

            <Route component={NotFound} />
          </Switch>
        </Layout>
      )}
    </Switch>
  )
}

export default Routing
