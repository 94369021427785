import { Card, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Chart, DonutChart, Interval, Tooltip } from 'bizcharts'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dashboardStyles } from './Dashboard@styles'
import { DashboardStateTypes } from './Dashboard@types'
import { ordersChartData, ordersTypeChartData } from './_temp'

const useStyles = makeStyles(dashboardStyles)

const Dashboard = () => {
  // state
  const [state, setState] = useState<DashboardStateTypes>({
    ordersChartsYearsSelect: false,
    ordersChartsSelectedYear: '2021',
  })

  // hooks
  const { t } = useTranslation()
  const classes = useStyles()

  const handleOpenOrderChartsYearsSelect = () => {
    setState((prevState) => ({
      ...prevState,
      ordersChartsYearsSelect: !prevState.ordersChartsYearsSelect,
    }))
  }

  const handleChangeOrdersChartYear = (
    event: ChangeEvent<{ value: unknown }>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      ordersChartsSelectedYear: event.target.value,
    }))
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card className={classes.card}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {t`Dashboard.Orders_Type_Orverview`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DonutChart
                      data={ordersTypeChartData || []}
                      autoFit
                      height={300}
                      radius={0.8}
                      padding="auto"
                      angleField="value"
                      colorField="type"
                      pieStyle={{ stroke: 'white', lineWidth: 5 }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card className={classes.card}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                      {t`Dashboard.Orders_Overview`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.yearSelect}>
                    <Select
                      labelId="orders-charts-years-select"
                      open={state.ordersChartsYearsSelect}
                      onClose={handleOpenOrderChartsYearsSelect}
                      onOpen={handleOpenOrderChartsYearsSelect}
                      onChange={handleChangeOrdersChartYear}
                      value={state.ordersChartsSelectedYear}
                    >
                      <MenuItem value={2021}>2021</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Chart height={300} autoFit data={ordersChartData}>
                      <Interval position="month*ordersCount" />
                      <Tooltip shared />
                    </Chart>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
